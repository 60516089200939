<template>
    <div class="money-audit">
        <div class="result-line">
            <span class="label">审核结论:</span>
            <div class="el-radio-outer">
                <el-radio
                        :label="1"
                        v-model="auditResult"
                >通过
                </el-radio>
                <el-radio
                        :label="2"
                        v-model="auditResult"
                >拒绝
                </el-radio>
            </div>
        </div>
        <div class="result-line bottom" v-if="auditResult == 2">
            <div class="reject-text">拒绝原因:</div>
            <div class="select-outer">
                <el-select
                        v-model="rejectReason"
                        placeholder="请选择"
                        popper-class="select-self"
                >
                    <el-option
                            class="select-el-option"
                            v-for="(item,index) in rejectReasonOption"
                            :label="item.value"
                            :key="index"
                            :value="item.value"
                    >
                    </el-option>
                    <el-option
                            class="select-el-option"
                            label="自定义"
                            value="-1"
                    >
                    </el-option>
                </el-select>
                <div v-if="rejectReason == -1"
                        class="textarea-outer">
                    <el-input
                            class="textarea-el"
                            type="textarea"
                            v-model="rejectReasonSelf"
                    ></el-input>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import { getDict } from '@/api/system/dict'
  export default {
    name: 'moneyAudit',
    data() {
      return {
        auditResult: 1,
        rejectReason: '',
        rejectReasonSelf: '',
        rejectReasonOption: []
      }
    },
    created(){
        this.getAuditReasonsDist();
    },
    methods: {
      sendReason() {
        let params = {
          decision: this.auditResult,
        }
        if(this.auditResult == 2){
          if(this.rejectReason != -1){
            params.rejectReason = this.rejectReason
          }else {
            params.rejectReason = this.rejectReasonSelf;
          }
        }
        this.$emit('send-reason',params);
      },
      getAuditReasonsDist() {
        getDict({
          type: 'depCommRejectReason'
        }).then((res) => {
          this.rejectReasonOption = res.content;
        })
      }
    }
  }
</script>
<style lang="less" scoped>
    .label {
        font-size: 18px;
        padding: 10px 10px;
    }
    .result-line {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 20px;

        &.bottom {
            align-items: flex-start;
            margin-top: 10px;

            .reject-text {
                margin-right: 10px;
            }

            .textarea-el {
                margin-top: 10px;
            }

            .select-el,
            .textarea-el {
                width: 300px;
            }
        }
    }
</style>
