<template>
    <div class="withdraw-info">
      <el-row class="row-box">
        <el-col>
          <span class="applyer-label">银行卡照片:</span>
          <el-image
            v-if="backInfo.bankFrontImg"
            class="bank-card-img img-el margin-right5"
            :src="backInfo.bankFrontImg"
            :preview-src-list="[backInfo.bankFrontImg]"
          />
          <el-image
            v-if="backInfo.bankBackImg"
            class="bank-card-img img-el"
            :src="backInfo.bankBackImg"
            :preview-src-list="[backInfo.bankBackImg]"
          />
        </el-col>
      </el-row>
      <el-row class="row-box">
        <el-col>
          <span class="applyer-label">USDT:</span>
          <span class="label-right">
                <template v-if="usdtInfo.qrPic">
                  <el-image
                    v-if="usdtInfo.qrPic"
                    class="qr-code img-el"
                    :src="usdtInfo.qrPic"
                    :preview-src-list="[usdtInfo.qrPic]"
                  />
                  <br>
                <span class="address-span">{{usdtInfo && usdtInfo.address}}</span>
                </template>
                <template v-else>
                  <span>暂无信息</span>
                </template>
              </span>
        </el-col>
      </el-row>
      <el-row class="row-box">
        <el-col>
          <span class="applyer-label">OTC365:</span>
          <span class="label-right">
                <template v-if="otcInfo.qrPic">
                  <el-image
                    v-if="otcInfo.qrPic"
                     class="qr-code img-el"
                    :src="otcInfo.qrPic"
                    :preview-src-list="[otcInfo.qrPic]"
                  />
                  <br>
                  <span class="address-span">{{otcInfo && otcInfo.address}}</span>
                </template>
                <template v-else>
                  <span>暂无信息</span>
                </template>
              </span>
        </el-col>
      </el-row>
    </div>
</template>

<script>
  export default {
    name: 'withdrawInfo',
    props: {
      usdtInfo: {
        type: Object,
        default(){
          return {
            qrPic: '',
            address: '',
          };
        }
      },
      otcInfo: {
        type: Object,
        default(){
          return {
            qrPic: '',
            address: '',
          };
        }
      },
      backInfo: {
        type: Object,
        default(){
          return {
            bankAccount: '',
            bankName: '',
            bankOutlet: '',
            swiftCode: '',
            bankFrontImg: '',
            bankBackImg: '',
          };
        }
      },
    },
    methods: {
      previewHandle(imgUrl) {
        this.$emit('previewImg', imgUrl);
      }
    }
  }
</script>

<style lang="less" scoped>
.bank-card-img {
  width: 170px;
  height: 120px
}
  .qr-code {
    width: 90px;
    height: 90px
  }
  .row-box {
    margin-bottom: 20px;
  }
  .applyer-label {
       display: inline-block;
       font-size: 18px;
       width: 100px;
   }
  .address-span {
    display: inline-block;
    margin-left: 100px;
    padding-top: 10px;
  }
  .img-el {
    cursor: zoom-in;
  }
</style>
